@import "./DashboardCard.scss";

#announcements-card {
  position: relative;
  height: 100%;

  .title-bar {
    padding-right: 20px;
    .create-announcement-button {
      color: #db1e36;
      cursor: pointer;
    }
  }

  .no-announcements {
    flex-grow: 1;

    font-size: 14px;
    font-weight: bold;
    color: #282828;
  }

  .announcement-content {
    padding: 20px 20px 10px 20px;
    background-color: #f8f8f8;
    border-radius: 8px;
    margin: 20px;
    margin-bottom: 10px;
    h4 {
      margin: 0 0 10px 0;
    }

    .announcement-date-widget-wrapper {
      font-size: 13px;
      margin: 10px 0;

      .calendar-icon {
        font-size: 15px;
      }
    }

    .announcement-content-text {
      color: #444462;
      font-size: 14px;
      margin: 10px 0;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;

      height: 3.5em;
      word-break: break-all;
    }

    .announcement-roles-widget-wrapper {
      flex-wrap: wrap;

      .announcement-group-label {
        padding: 5px;
        border-radius: 5px;
        font-size: 12px;
        font-weight: bold;
        user-select: none;
        margin: 5px 5px 5px 0px;

        &.high-school-group {
          color: #b9519e;
          background-color: #b9519e26;
        }

        &.middle-school-group {
          color: #ff8300;
          background-color: #ff830026;
        }

        &.adult-group {
          color: #70449b;
          background-color: #70449b26;
        }

        &.admin-group {
          color: #93d500;
          background-color: #93d50026;
        }
      }
    }

    .announcement-details-button {
      color: #2f2f51;
      text-transform: none;
      padding: 0;

      &:hover {
        background-color: transparent;
      }
    }
  }

  .announcement-carousel-buttons {
    box-sizing: border-box;
    padding: 0px 20px 10px 20px;

    .announcement-carousel-button {
      color: #656565;
      font-size: 12px;
      text-transform: none;
      padding: 10px;

      &[disabled] {
        opacity: 0.4;
      }

      &:hover {
        background-color: #f8f8f8;
      }

      .carousel-icon {
        height: 0.8em;
        color: inherit;
      }
    }
  }
}
