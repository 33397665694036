@import "./DashboardCard.scss";

#expiring-resources-card {
  .column-headers {
    p.date-headers {
      margin-right: 20px;
    }
  }

  .column-data {
    .title-container {
      margin-left: 15px;
      max-width: 270px;
      width: 67%;

      .alert-icon {
        margin-right: 10px;
      }
    }

    p {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    p.title {
      font-weight: 700;
    }
    p.date {
      margin-right: 20px;
      color: #f6553f;
    }
  }

  .expiring-soon {
    background-color: #ffe2de;
  }
}
