.approve-reject-container {
  background-color: #ffe2de;
  padding: 12px 20px;
  margin-right: 20px;
  border-radius: 8px;

  p {
    margin: 0;
    margin-right: 1.25em;
    font-weight: 700;
    font-size: 14px;
  }

  img {
    cursor: pointer;

    &:first-of-type {
      margin-right: 12px;
    }
  }
}
