.card-header {
  padding: 20px 0px 0px 20px;
  margin: 0;
}

#dashboard-tab {
  font-size: 13px;
  font-weight: 500;
  text-transform: capitalize;
  padding: 12px;
}

.column-headers {
  height: 30px;
  margin: 0 28px 10px 28px;
  font-size: 14px;
  font-weight: 400;
  color: #444462;
}

.column-data {
  height: 43px;
  margin: 0 15px 10px 15px;
  background-color: #f7f7f9;
  border-radius: 8px;
  cursor: pointer;

  p {
    font-size: 14px;
  }
}

.scroll-container {
  overflow-y: auto;
  -webkit-mask-image: linear-gradient(to bottom, black 90%, transparent 100%);
  mask-image: linear-gradient(to bottom, black 90%, transparent 100%);
}

.no-data {
  height: 100%;

  p {
    font-size: 14px;
    font-weight: 700;
    text-align: center;
  }
}
