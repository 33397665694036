@import "./DashboardCard.scss";

#top-guide-menu {
  height: 100%;
  width: 100%;

  .menu-card {
    height: 224px;
    width: 100%;
    margin: 8px 8px 16px 8px;
    padding: 18px;
    cursor: pointer;

    img {
      height: 40px;
      margin-top: 30px;
    }

    h4 {
      font-size: 18px;
      font-weight: 700;
      margin: 18px 0;
    }

    p {
      font-size: 14px;
      font-weight: 400;
      text-align: center;
      width: 80%;
      max-width: 200px;
      margin: 0;
    }
  }

  .menu-card:first-of-type {
    margin-left: 0;
  }
  .menu-card:last-of-type {
    margin-right: 0;
  }
}
