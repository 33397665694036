#create-skill-training {
  padding: 20px;

  .form-container {
    flex-wrap: wrap;
  }

  .width-100 {
    width: 100%;
  }
  .width-48 {
    width: 48%;
  }
  .min-5-width {
    min-width: 5em;
  }
  .width-25 {
    width: 25%;
  }
  .width-30 {
    width: 30%;
  }
  .width-15 {
    width: 15%;
  }
  .width-40 {
    width: 40%;
  }

  .cost-textbox {
    max-width: 11em;
  }
  .duration-datebox {
    max-width: 15em;
  }

  .money-icon {
    font-size: 18px;
  }

  .small-p {
    font-size: 12px;
    margin: 0;
  }

  .learn-and-work {
    margin: 10px 0;
  }

  .hidden-span {
    height: 40px;
  }

  .hidden-checkbox {
    display: none;
  }

  .static-input-label {
    // This font size is different than other forms because 16 is a larger size in this file (for unknown reasons)
    font-size: 15px;
  }

  .static-length-container {
    .field-container:first-of-type {
      margin-right: 2em;
    }
  }

  .button-container {
    margin-top: 2em;

    .right-side {
      flex: 1;
    }

    button {
      min-width: 120px;
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
