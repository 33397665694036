#user-details {
  .colapsable-sections {
    :last-child {
      margin-bottom: 0;
    }
  }

  .print-componenets {
    display: none;
  }
}

@media print {
  @page {
    // size: landscape;
    margin: 8mm;
  }
}
