.edit-user-detail {
  .edit-user-detail-header {
    margin-top: 10px;
    .approval-status-wrapper {
      padding: 10px 15px 7px;
      background: #F7F7F9;
      border-radius: 8px;
    }

    .approval-status {
      font-size: 10px;
      margin-right: 15px;
      margin-bottom: 2px;
    }
  }

  .edit-user-detail-body {
    flex-wrap: wrap;
    max-height: 35em;
    column-gap: 10%;

    &.key-contact-body,
    &.counselor-body,
    &.district-admin-body,
    &.institution-admin-body,
    &.super-admin-body {
      max-height: 20em;
    }
    &.institution-admin-body,
    &.community-body {
      max-height: 22em;
    }

    .field-container {
      margin-bottom: 10px;

      input[type="number"] {
        padding: 8.5px 14px;
      }
    }

    .static-input-label {
      margin-bottom: 5px;
      font-size: 12px;
    }
  }

  .edit-user-detail-footer {
    padding: 30px 0 10px 0;

    .right-side {
      button {
        margin-right: 20px;

        &:last-child {
          margin-right: 0px;
        }
      }
    }
  }

  .transfer-history {
    span {
      padding-right: 10px;

      &.split-line {
        border-left: 1px solid #F00;
      }
    }
  }

  .verify-email-button {
    position: absolute;
    margin-top: 10px;
    z-index: 1;

    @media print {
      display: none;
    }
  }
}