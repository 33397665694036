#landing-page {
  height: 100vh;
  max-height: 100vh;
  width: 100vw;
  max-width: 100vw;
  display: flex;

  .content-container {
    height: 100%;
    text-align: center;

    .top-section {
      height: calc(65% - 2em);
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      max-width: 600px;
      margin: auto;
      width: 100%;
    }

    .bottom-section {
      height: calc(35% - 2em);
      padding: 0em 4em;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      max-width: 600px;
      margin: auto;
    }
  }

  .left-container {
    background-color: #ededf6;
    width: 50%;
    padding: 0em 4em;
    h1 {
      font-size: 44px;
      margin-top: 0;
    }

    p {
      margin: 0.5em;
    }
    hr {
      border: none;
    }
  }

  .right-container {
    background-color: white;
    width: 50%;
    padding: 0em 4em;
    position: relative;
    overflow: auto;
  }

  h2 {
    font-size: 28px;
  }

  p.subtitle {
    margin-top: -5px;
    width: 50%;
  }

  .forgot-password {
    margin-top: 20px;
    width: 100%;
  }

  a {
    text-decoration: none;
  }

  .centered-link {
    margin-top: 30px;
    width: 100%;
    text-align: center;
    text-decoration: none;
  }

  hr {
    width: 100%;
    border: solid #d6d5d5;
    border-width: 1px 0px 0px 0px;
    margin: 2em 0em;
  }

  strong {
    color: #db1e36;
  }

  p.static-input-label {
    font-size: 12px;
    text-align: left;
    text-transform: uppercase;
    width: 100%;
    margin: 1.25em 0 0.5em 0;
  }

  .submit-button {
    margin-top: 1em;
    width: 100%;
  }

  .link-button {
    max-width: 420px;
  }

  .main-input {
    width: 100%;
    text-align: left;
  }

  .footer {
    position: absolute;
    width: calc(100% - 8em);
    text-align: center;
    transform: translateY(-65px);
  }
}

// This turns that nasty blue autofill color into a non-sucky white
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px white inset !important;
  box-shadow: 0 0 0 50px white inset !important;
}
