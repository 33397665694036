#resource-tag-details {
  height: 100%;
  .card-background {
    padding: 20px 20px 30px 20px;
  }
  .details-header {
    padding-bottom: 16px;

    .back-button {
      cursor: pointer;
    }
    h3 {
      margin: 0;
    }
  }

  .card-content {
    border-top: 1px solid #efeff2;
    p:first-child {
      color: #a1a1a1;
      font-size: 12px;
      font-weight: 700;
      min-width: 10em;
      margin: 0;
    }
    p:nth-child(2) {
      margin: 0 0 0 2em;
    }
    span {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin-top: 1em;
      &:first-child {
        margin-top: 2em;
      }
    }
  }

  .MuiButton-root {
    width: 9em;
  }
}
