#create-update-community-page {
  height: 100%;
  padding: 20px;

  h4 {
    margin-bottom: 0;
  }

  .create-form-container {
    h3 {
      margin-top: 0;
    }

    .form-content-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      row-gap: 15px;
      margin-bottom: 2em;

      .field-container {
        flex-basis: 45%;
        font-weight: 500;
        font-size: 14px;
      }
    }

    .form-input {
      width: 100%;

      &.mr-20 {
        margin-right: 20px;
      }
    }

    .MuiAutocomplete-root {
      width: 100%;
    }

    .right-side {
      flex: 1;
    }

    .form-button {
      min-width: 120px;

      &.cancel-button {
        margin-right: 20px;
      }
    }

    .file-name {
      padding: 15px 18px;
      background-color: #EFEFF2;
      border-radius: 8px;
    }
  }
}