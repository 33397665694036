#resource-list {

  .page-content {
    .table {
      height: 69vh;
      width: 97%;
      margin: auto;
    }
  }
  .table-buttons-and-filters{
    button{
      width: auto;
    }
  }

  .select-all-container {
    height: 48px;
    width: fit-content;
    margin-left: 20px;
    padding: 0 15px;
    background-color: #F7F7F9;
    border-radius: 8px;

    p {
      margin-right: 15px;
      font-weight: 500;
    }
    button {
      height: 34px;
      width: 117px;
    }
  }
  .search{
    width: clamp(150px, 350px, 497px);
  }
}
