#details-accordion {
  border-radius: 10px !important;
  margin-bottom: 20px;

  box-shadow: 0px 8px 10px rgba(181, 181, 181, 0.25) !important;

  .not-expandable {
    &.MuiAccordionSummary-root {
      cursor: default;
    }
  }

  &:before {
    display: none;
  }

  .accordion-summary {
    font-weight: bold;
    text-transform: capitalize;
    min-height: 72px !important;

    button {
      height: 42px;
    }

    .summary-content {
      width: 100%;
    }

    .overview-summary-edit-btn {
      width: 120px;
      margin-left: 30px;

      @media screen and (max-width: 750px) {
        margin-left: 10px;
      }
    }

    .summary-description {
      height: 100%;

      .vertical-line {
        margin: 0 15px 0 15px;
        border-left: 1.5px solid #2F2F51;
        height: 100%;
      }

      p {
        font-size: 12px;
        font-weight: normal;
        color: #282828;
        margin: 0;
      }
    }
  }

  .accordion-details {
    border-top: 1px solid #DEDEE6;
    padding: 10px 20px !important;

    h1 {
      margin: 0;
    }

    a {
      color: #282828;
    }
  }

  .overview-header-wrapper {
    margin: 10px 0 20px 0;

    h2 {
      font-size: 21px;
      margin: 0 0 10px 0;
    }

    p {
      margin: 0;
    }
  }

  .overview-details {
    margin-bottom: 10px;

    @media screen and (max-width: 750px) {
      flex-wrap: wrap;
    }
  }

  .overview-content-wrapper {
    position: relative;
    width: 100%;

    .overview-content-row {
      .overview-label{
        width: 12%;
        min-width: 150px;
        text-transform: uppercase;
        color: #a1a1a1;
        font-size: 12px;
        font-weight: bold;

        padding: 0 0 20px 0;

        flex-shrink: 0;
      }

      .overview-data {
        display: inline-block;
        width: 36%;
        color: black;
        font-size: 16px;
        font-weight: normal;
        white-space: normal;

        flex-shrink: 0;
      }

      @media screen and (max-width: 750px) {
        flex-direction: column;

        .overview-label {
          width: 100%;
          padding-bottom: 10px;
        }
        .overview-data {
          width: 100%;
          padding-bottom: 30px;
        }
      }
    }
  }
}
