#signup-page {
  height: auto !important;
  padding-bottom: 5em;
  padding-top: 4em;

  .back-button {
    position: absolute;
    top: 1em;
    left: 1em;
  }
  .main-input {
    width: 100%;
    text-align: left;
  }
}
