.create-edit-announcement-container {
  padding: 1.5em;

  .form-content {
    flex-wrap: wrap;
  }

  h3 {
    margin: 0;
    padding-bottom: 1em;
    width: 100%;
    border-bottom: 1px solid #efeff2;
  }

  .full-width {
    width: 100%;
  }

  .half-width {
    width: 48%;
  }

  .static-input-label {
    font-size: 14px;
    opacity: .7;
  }

  .bottom-buttons{
    width: 100%;
    margin-top: 30px;

    .right-side {
      flex: 1;
    }

    button {
      min-width: 120px;
      &:first-child {
        margin-right: 20px;
      }
    }
  }
}

.overlap-dialog {
  h2 {
    text-align: center;
    margin-top: 20px;
    color: #DB1E36;
    font-weight: bold;
  }

  .MuiPaper-root {
    width: 650px;
    padding: 0 15px 20px;

    .MuiDialogContent-root {
      padding: 0 40px 20px;
    }

    p {
      text-align: center;
      color: #282828;
      font-weight: 500;
      width: 80%;
      margin: 0 auto 25px;
      line-height: 20px;
    }

    .overlap-message {
      border-radius: 6px;
      background-color: #E3E3F0;
      padding: 16px;
      text-align: center;
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .action-buttons {
    margin-top: 50px;

    button {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
}

.delete-announcement-confirm-dialog {
  h2 {
    text-align: center;
    margin-top: 20px;
    font-weight: bold;
    padding-bottom: 5px;
  }

  .MuiPaper-root {
    width: 600px;
    padding: 50px 0 30px;

    p {
      text-align: center;
      color: #282828;
    }
  }

  .action-buttons {
    margin-top: 50px;

    button {
      padding-left: 0;
      padding-right: 0;
      width: 180px;
    }
  }
}