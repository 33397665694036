#new-user-page {


  h4 {
    margin-bottom: 0;
  }

  .create-form-container {
    background-color: white;
    border-radius: 1em;
    padding: 20px;
    box-shadow: 0px 8px 10px rgba(181, 181, 181, 0.25);
    h3 {
      margin-top: 0;
    }

    .approval-status {
      background-color: #f7f7f9;
      padding: 8px;
      border-radius: 4px;
      margin-top: 8px;
      p {
        margin: 0;
        margin-right: 8px;
      }
    }

    .general-forms {
      flex-wrap: wrap;
      max-height: 40em;
      column-gap: 10%;

      .field-container {
        display: flex;
        flex-direction: column;
        width: 45%;
        font-weight: 500;
        font-size: 14px;
        height: 7em;
      }

      &.key-contact-form,
      &.counselor-form,
      &.district-admin-form,
      &.community-form,
      &.institution-admin-form,
      &.super-admin-form {
        max-height: 20em;
      }
    }

    .bottom-section {
      margin-bottom: 2em;
    }

    .form-input {
      width: 100%;
    }
    .MuiAutocomplete-root {
      width: 100%;
    }
  }
  .form-button {
    width: 10em;
  }
}
