#organization-list {

  .page-content {
    .table {
      height: 69vh;
      width: 97%;
      margin: auto;
    }
  }

  .pl-20 {
    padding-left: 40px !important;
  }

  .table-buttons-and-filters {
    margin-right: 2%;

    .new-organization-btn {
      width: unset;
      padding: 0 40px;
    }
  }
}
