#profile-nav {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  height: 3em;

  background-color: transparent;

  .padded-icon {
    font-size: 12pt;
    margin-right: 6px;
  }

  .inquiries-button {
    height: 45px;
    align-self: flex-end;
    margin-right: 20px;
    cursor: pointer;
  }

  .profile-button {
    position: relative;

    box-sizing: border-box;
    width: 250px;
    height: 100%;
    background-color: #c1c1cb4c;
    border-radius: 8px;

    padding: 8px;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;

    &:hover {
      cursor: pointer;
    }

    .profile-avatar {
      width: 30px;
      height: 30px;
      background-color: #6d6d85;
      color: white;
      font-weight: 500;
      font-size: 14px;
      text-transform: uppercase;
    }

    .profile-info {
      flex-grow: 1;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      padding-left: 8px;

      .profile-info-name {
        font-size: 0.9em;
        font-weight: bold;
        text-transform: capitalize;
      }

      .profile-info-title {
        font-size: 0.8em;
        font-weight: 500;
        text-transform: capitalize;
      }
    }

    .profile-expand {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .icon-lg {
        font-size: 2.5em;
      }
    }
  }
}
