#organization-detail-page {
  padding: 10px 20px;

  h3, h4 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    button {
      width: 120px;
    }
  }

  .detail-content {
    padding: 20px 0 10px;
    color: #282828;

    .organization-type {
      padding: 5px 0;
    }

    .detail-item {
      display: flex;
      align-items: center;
      font-size: 16px;

      .detail-item-type {
        min-width: 140px;
        display: inline-block;
        color: #A1A1A1;
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
      }
    }

    .image-wrapper {
      text-align: right;

      img {
        height: 100%;
        width: 100%;
        max-height: 15em;
        max-width: 15em;
      }

      @media (max-width: 900px){
        text-align: left;
        margin-top: 30px;
      }
    }
  }
}