#organization-settings {

  .organization-settings-container {
    padding: 20px;

    .header-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;

      h3, h4 {
        margin-top: 0;
        margin-bottom: 0;
        color: #2F2F51;
      }

      button {
        width: 120px;
      }
    }

    h4 {
      margin-top: 0;
      margin-bottom: 20px;
    }

    .detail-content {
      padding: 20px 0 20px;
      color: #282828;

      .detail-item {
        display: flex;
        align-items: center;
        font-size: 16px;
        margin-bottom: 15px;

        .detail-item-type {
          min-width: 140px;
          display: inline-block;
          color: #A1A1A1;
          font-size: 12px;
          font-weight: bold;
          text-transform: uppercase;
        }
      }

      .image-wrapper {
        text-align: right;

        img {
          max-width: 80%;
        }

        @media (max-width: 900px){
          text-align: left;
          margin-top: 30px;
        }

        img {
          max-width: 100%;
        }
      }
    }

    .form-content-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      row-gap: 15px;
      margin-bottom: 2em;

      .field-container {
        flex-basis: 45%;
        font-weight: 500;
        font-size: 14px;
      }
    }

    .form-input {
      width: 100%;

      input.Mui-disabled {
        background: #EFEFF2;
      }
    }

    .form-button {
      width: 10em;
    }
  }
}
