#user-details {

  .approval-status {
    position: relative;
  }

  .verify-email-button {
    position: absolute;
    margin-top: 10px;
    z-index: 1;

    @media print {
      display: none;
    }
  }
}
