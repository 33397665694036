#generate-code-modal {
  .MuiPaper-root {
    max-width: none;
  }
  .modal-content {
    padding: 3em 7em;
  }

  h2 {
    color: var(--color-primary);
  }

  .code-container {
    padding: 1em 1.5em;
    background-color: var(--color-light-grey-00);
    border-radius: 10px;
    margin: 2em 0;
    h4 {
      color: var(--color-primary);
      margin: 0 0 10px 0;
    }
    p {
      font-weight: 700;
      font-size: 42px;
      letter-spacing: 0.01em;
      color: var(--dark-d-02);
      line-height: 50px;
      margin: 0;
    }
  }

  button {
    height: 3em;
    width: 7em;
    font-size: 20px;
    margin-top: 2em;
  }
}
