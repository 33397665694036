@import "./DashboardCard.scss";

#resource-applicants-card {
  height: 100%;
  max-height: 500px;

  [role=tabpanel] {
    flex: 1;
  }

  .card-content {
    height: 100%;
  }

  .column-headers {
    p.title {
      width: 75%;
    }
    p.applicants {
      width: 25%;
      text-align: center;
    }
  }

  .column-data {
    padding: 0 15px;

    p {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    p.title {
      width: 75%;
      font-weight: bold;
    }
    p.applicants {
      width: 25%;
      font-size: 16px;
      text-align: center;
    }
  }

  .empty-list {
    height: 100%;
    font-weight: bold;
  }
}
