#resource-tags-modal {
  .modal-content {
    position: relative;
    height: 643px;
    width: 776px;
    min-width: 65vw;
  }

  .content-container {
    height: 100%;
    margin: 50px 30px;
    text-align: center;
  }
}
