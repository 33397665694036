.send-confirm-dialog {
  h2 {
    text-align: center;
    margin-top: 20px;
    font-weight: bold;
    padding-bottom: 5px;
    text-transform: capitalize;
  }

  .dialog-text {
    padding-top: 5px;
    padding-left: 50px;
    padding-right: 50px;
    width: 423px;
  }

  .MuiPaper-root {
    width: 600px;
    padding: 50px 0 30px;

    p {
      font-size: 17px;
      text-align: center;
      color: #282828;
    }
  }

  .action-buttons {
    margin-top: 50px;

    .cancel-button {
      padding-left: 0;
      padding-right: 0;
      width: 172px;

    }
    
    .send-button {
      padding-left: 0;
      padding-right: 0;
      width: 258px;
    }

  }
}