#create-edit-pathway {

  .form-container {
    padding: 20px;
  }

  h3 {
    margin-top: 8px;
  }

  .status {
    padding: 10px 15px 7px;
    background: #F7F7F9;
    border-radius: 8px;
    cursor: pointer;

    p {
      font-size: 10px;
      margin: 0 15px 2px 0;
    }
  }

  .divider {
    margin: 15px 0;
    border-bottom: 1px solid #9797A8;
  }

  .form-column {
    width: 100%;

    &:first-child {
      margin-right: 80px;
    }
  }

  .section-header {
    margin-top: 46px;
    font-size: 21px;
  }

  .static-input-label {
    // This font size is different than other forms because 16 is a larger size in this file (for unknown reasons)
    font-size: 14px;
  }

  .course-form {
    .course-container {
      padding-bottom: 17px;
    }

    .course-divider {
      margin: 28px 0;
    }

    p.course-number {
      margin: 3px 0 0 0;
      font-size: 14px;
      font-weight: 700;
      color: #6D6D85;
    }
  }

  .course-control {
    p {
      margin: 0;
    }
    img {
      margin-left: 5px;
    }
    .eye-icon {
      height: 15px;
      margin-left: 5px;
    }
  }

  span.hide-button {
    font-size: 12px;
    cursor: pointer;
  }

  span.remove-button {
    margin-left: 30px;
    font-size: 12px;
    color: #DB1E36;
    cursor: pointer;
  }

  span.add-button {
    width: fit-content;
    margin-top: 17px;
    font-size: 14px;
    font-weight: 700;
    color: #DB1E36;
    cursor: pointer;

    p {
      margin: 0;
    }

    .add-icon {
      height: 20px;
    }
  }

  .certificate-container {
    margin-bottom: 17px;
  }

  .button-container {
    margin-top: 30px;

    .right-side {
      flex: 1;
    }

    button {
      min-width: 120px;
      &:first-child {
        margin-right: 20px;
      }
    }
  }
}
