#edit-financial-aid-info {
  .card-background {
    padding: 20px;
  }
  h3 {
    font-size: 18px;
    margin: 0px;
  }

  .form-container {
    margin-top: 30px;

    div.form-section {
      margin-bottom: 30px;

      h3.section-header {
        margin-bottom: 15px;
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
      }

      .text-field {
        margin-bottom: 20px;
        font-size: 12px;
        font-weight: 500;
        text-transform: uppercase;
      }
    }
  }

  .form-button {
    width: 150px;
  }
}
