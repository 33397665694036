#college-application-checklist-list-container {
  .page-content {
    .table {
      height: 69vh;
      width: 97%;
      margin: auto;
    }
  }

  .metabase-link {
    background-color: white;
    color: #DB1E36;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    box-shadow: 0px 8px 10px rgba(181, 181, 181, 0.25);
    border: 2px solid #DB1E36;
    border-radius: 8px;
  }
}
