@import "./DashboardCard.scss";

#master-key-card {
  height: 100%;

  .title-bar {
    .create-masterkey-button {
      color: #DB1E36;
      margin: 20px 30px 0 0;
      cursor: pointer;
    }
  }

  [role=tabpanel] {
    flex: 1;
  }

  .tab-content {
    box-sizing: border-box;
    height: 100%;
    padding: 10px 20px;
  }
  .subcard {
    background-color: #f7f7f9;
    border-radius: 10px;
    padding: 10px;
    cursor: pointer;

    font-size: 15px;
    margin-top: auto;
    margin-bottom: auto;

    .subcard-month {
      padding: 10px;
      aspect-ratio: 1;
      margin-right: 1em;
      border-radius: 10px;
      background-color: rgba(112,68,155,0.2);
      color: #70449B;
      font-weight: bold;
      text-transform: uppercase;
    }

    .subcard-title {
      color: #444462;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .subcard-submissions {
      color: #70449B;
      font-weight: bold;
      margin-left: 20px;
    }
  }

  .all-master-keys-link {
    font-size: 14px;
    font-weight: 700;

    p {
      cursor: pointer;
    }
    .chevron-icon {
      height: 20px;
      cursor: pointer;
    }
  }
}
