@import "./DashboardCard.scss";

#quicklinks-card {
  height: 100%;
  width: 100%;
  .card-header {
    h3 {
      margin: 0;
      margin-left: 0.5em;
    }
  }

  .tab-content {
    text-align: center;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.5em;
    padding: 10px 20px;
    flex: 1;
  }
  .quick-link {
    background-color: #54c0e8;
    border-radius: 6px;
    padding: 6px;
    font-size: 14px;
    font-weight: 500;
    color: white;
    text-decoration: none;
    cursor: pointer;

    max-height: 3em;
  }
}
.cis-link {
  width: 100%;
  padding: 6px 16px;
  text-decoration: none;
  color: black;
}
