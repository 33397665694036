.master-key-submission-list {
  padding: 1.5em;
  .details-header {
    padding-bottom: 1em;
    border-bottom: 1px solid #efeff2;
    .back_arrow {
      cursor: pointer;
      display: flex;
      align-items: center;
    }
    h3 {
      margin: 0;
      font-size: 18px;
    }
  }

  .master-key-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
  }

  .table {
    height: 69vh;
    width: 97%;
    margin: auto;
  }
}