#pathway-details {

  .updated-banner {
    position: relative;
    padding: 17px 21px;
    background-color: #FFE2DE;
    border-radius: 8px;
    margin-bottom: 20px;

    p {
      font-size: 14px;
      margin-bottom: 0;
    }

    .close-icon {
      position: absolute;
      top: 14px;
      right: 12px;
      height: 20px;
      width: 20px;
      cursor: pointer;
    }
  }

  .section-header {
    margin-top: 50px;
  }

  .colapsable-sections {
    :last-child {
      margin-bottom: 0;
    }
  }
}
