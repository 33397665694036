#resource-tags-list {

  .list-header {
    padding: 20px 20px 10px 20px;

    .back-button {
      width: fit-content;
      cursor: pointer;
      margin-bottom: 0.5em;
    }
    h3,
    p {
      margin: 0;
    }
    .create-button {
      height: 3em;
    }
  }

  .page-content {
    .table {
      height: 69vh;
      width: 97%;
      margin: auto;
    }
  }

  .top-container {
    padding: 20px 20px 10px 20px;

    form {
      width: 50%;
    }

    .search {
      width: 100%;
    }
  }

  .MuiButton-root {
    width: 10em;
  }
}
