#signup-code-page {

  div.content {
    height: 75%;
  }

  section.form {
    margin: 0 25px;

    div.form-container {
      width: 100%;
      max-width: 500px;
    }

    div.header-text {
      padding: 0 5px;
    }

    div.codeCol {
      padding: 0 5px;
    }

    input.digit {
      height: 94px;
      max-width: 70px;
      text-align: center;
      font-size: 40px;
      font-weight: 600;
      border: solid 2px;
      border-color: red;
      border-radius: 9px;
    }
  }

      .verify-email-button {
      margin-top: 35px;
      margin-bottom: 35px;
      max-width: 400px;
      align-self: center;
      height: 60px;
      width: 75%;
      font-size: 20px;
    }

  p.confirmation-code {
    font-size: 17px;
    font-weight: 550;
    color: var(--color-dark-d-06);
    text-align: center;
  }

  p.link {
    margin-top: 5px;
    font-size: 17px;
    font-weight: 550;
    cursor: pointer;
  }

  /***** Turn-off input number arrows *****/
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
}
