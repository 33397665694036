#session-expired-modal {

  .content-container {
    height: 100%;
    margin: 50px 100px;
    text-align: center;

    h3 {
      margin: 0;
      margin-top: 25px;
    }

    p {
      line-height: 145%;
    }
  }
}
