#bulk-add-points-modal {

  .modal-content {
    position: relative;
    height: 643px;
    width: 776px;
  }

  .content-container {
    height: 100%;
    margin: 50px 30px;
    text-align: center;

    .MuiOutlinedInput-input {
      text-align: center;
    }

    .points-text {
      margin: 6px 0;
      font-size: 18px;
      font-weight: 500;
      color: #DB1E36;
    }

    .table {
      min-height: 350px;
      width: 100%;
      margin: auto;
    }

    .modal-buttons {
      width: 100%;
      margin-top: auto;
    }

    .back-button {
      position: absolute;
      top: 40px;
      left: 40px;
      font-weight: 500;
      color: #282828;
      cursor: pointer;
    }

    .slide-img {
      margin: 10px 0 50px 0;
    }

    .slide-msg {
      max-width: 350px;
      margin: 0;
      font-size: 16px;
      line-height: 145%;
    }
  }
}
