.user-careers-table {
  .careers-data-row {
    justify-content: stretch;
    align-items: flex-start;
    padding-bottom: 7px;
    margin-bottom: 7px;

    border-bottom: 1px solid #EFEFF2;

    &.header {
      display: flex;

      .careers-data-cell {
        .careers-data-label {
          display: block;
        }

        .careers-data-content {
          display: none;
        }
      }
    }

    .careers-data-cell {
      &:first-child {
        width: 50%;
        flex-shrink: 0;
      }

      .careers-data-label {
        display: none;

        text-transform: uppercase;
        color: #a1a1a1;
        font-size: 12px;
        font-weight: bold;
      }

      .careers-data-content {
        color: black;
        font-size: 14px;
        font-weight: normal;

        &.proper {
          text-transform: capitalize;
        }

        ol {
          box-sizing: border-box;
          margin: 0;
          padding: 0 0 0 16px;

          li {
            margin: 0 0 5px 0;
          }
        }
      }
    }

    @media screen and (max-width: 699px) {
      flex-direction: column;
      align-items: flex-start;

      padding: 10px;

      &.header {
        display: none!important;
      }

      .careers-data-cell {
        margin-bottom: 12px;

        width: 100%!important;

        .careers-data-label {
          display: block;
          margin-bottom: 3px;
        }
      }
    }
  }
}
