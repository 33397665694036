#inquiries-list {
  .list-header {
    padding: 20px 20px 10px 20px;

    .back-button {
      width: fit-content;
      cursor: pointer;
    }
    h3 {
      margin: 0;
    }
    p {
      margin-bottom: 0;
    }
  }

  .page-content {
    .table {
      height: 69vh;
      width: 97%;
      margin: auto;
    }
  }

  .top-container {
    padding: 20px 20px 10px 20px;

    form {
      width: 50%;
    }

    .search {
      width: 100%;
    }
  }

  .MuiButton-root {
    width: 10em;
  }
  .MuiDataGrid-cell{
    min-height: 52px !important;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}