.bulk-create-stepper {
  width: 100%;
  max-width: 2200px;
  align-self: center;
  flex-wrap: wrap;
  .stepper-step {
    span {
      height: 30px;
      width: 30px;
      background: #2f2f51;
      color: white;
      border-radius: 50%;
      margin-right: 20px;
      @media screen and (max-width: 1000px) {
        height: 25px;
        width: 25px;
        font-size: 16px;
      }
    }
  }
  p {
    margin: 0;
    font-size: 1em;
    font-weight: 500;
  }

  .current-step {
    p {
      color: red;
    }
  }

  svg{
    margin: 0em 2em;
  }
}
