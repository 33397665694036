@import "./DashboardCard.scss";

#approval-summary-card {
  height: 100%;
  .tab-content {
    display: grid;
    grid-template-columns: 50% 50%;
    padding: 20px;
  }
  .subcard {
    background-color: #f7f7f9;
    margin-bottom: 0.5em;
    border-radius: 10px;
    padding: 12px;
    margin-right: 0.5em;
    cursor: pointer;
    img {
      height: 32px;
      width: 32px;
      margin-right: 1em;
      border-radius: 5px;
    }
    p {
      margin: 0;
      font-size: 12px;
    }
  }
}
