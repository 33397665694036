.scholarship-application-details{
  padding: 1.5em;
  .details-header {
    padding-bottom: 1em;
    h3 {
      margin: 0;
    }
    h4 {
      width: 9em;
      max-width: 160px;
      font-size: 12px;
      color: #a1a1a1;
      font-weight: 700;
    }

    .button-border {
      text-align: left;
      border-radius: 8px;
      background-color: #FFE2DE;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
    }
    .MuiButton-root {
      padding: 0.25em 3em;
      border-width: 2px;
  }

  .approved-button{
    border: #19B269;
    background: #19B269;
    margin-left: 1rem;
  }

  .approval-button{
    padding: .9em;
    height: 17px;
    width: 60.06px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    height: 17px;
    width: 60.06px;
    margin-left: .5rem;
  }

    border-bottom: 1px solid #efeff2;
  }

  .main-content {
    width: 100%;

    .view-all {
      cursor: pointer;
      div {
        padding: 0.5em 1em;
        margin-right: 1em;
        border-radius: 4px;
        background: #db1e36;

        background-color: #db1e36;
        color: white;
        font-weight: 700;
      }
      p {
        color: #db1e36;
        font-weight: 700;
        margin: 0;
      }
    }

    .other-details {
      width: 100%;
      margin-top: 0.5em;
      .left-column {
        width: 50%;
      }
      .right-column {
        width: 48%;
      }
    }

    .main-details {
      width: 100%;
      margin-top: 0.5em;
      .left-column {
        width: 95%;
      }
    }

    span {
      margin-top: 16px;
      margin-bottom: 16px;

      // Flex is here to reduce the amount of times it's written in the ts file.
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      p {
        margin: 0;
      }
      p:first-child {
        width: 20em;
        max-width: 160px;
        font-size: 12px;
        color: #a1a1a1;
        font-weight: 700;
      }
      p:nth-child(2) {
        width: 100%;
      }
    }
  }
}
