#skill-details {
  .card-background {
    padding: 20px;
  }
  .list-header {
    padding-bottom: 10px;

    .back-button {
      cursor: pointer;
    }
    h3 {
      margin: 0;
    }
    p {
      margin-bottom: 0;
    }
  }

  .card-content {
    border-top: 1px solid #efeff2;
    p:first-child {
      color: #a1a1a1;
      font-size: 12px;
      font-weight: 700;
      margin-bottom: 0;
    }
    p:nth-child(2) {
      margin-left: 10em;
      margin-bottom: 0;
    }
  }

  .top-container {
    form {
      width: 50%;
    }

    .search {
      width: 100%;
    }
  }

  .MuiButton-root {
    width: 9em;
  }
}
