#career-cluster-details {
  .card-background {
    padding: 20px;
  }
  h3 {
    font-size: 18px;
    margin: 0px;
  }
  h4 {
    font-size: 16px;
  }
}

.content-header{
  padding-bottom: 1em;
  border-bottom: 1px solid #EFEFF2;
}
.cluster-skills-container {
  .skills-header {
    font-size: 12px;
    color: #a1a1a1;
  }
  span {
    display: flex;
    flex-direction: row;
    align-items: center;
    p {
      margin-top: 0;
    }
    :first-child {
      margin-right: 1em;
      padding-right: 1em;
      font-weight: 500;
      border-right: 2px solid red;
    }
    :nth-child(2) {
      font-size: 14px;
      font-weight: 400;
    }
  }
}

.edit-button {
  padding: 0.5em 2em !important;
}
