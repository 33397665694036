.notification-details {
  padding: 1.5em;
  .details-header {
    padding-bottom: 1em;
    border-bottom: 1px solid #efeff2;
    .back_arrow {
      cursor: pointer;
    }
    h3 {
      margin: 0;
    }
    button {
      padding: 0.25em 3em;
      border-width: 2px;
      border: 2px solid #db1e36;
    }

    .awaiting-approval {
      background-color: #ffe2de;
      padding: 12px 20px;
      margin-right: 20px;
      border-radius: 8px;

      p {
        margin: 0;
        font-weight: 700;
        font-size: 14px;
      }
    }
  }

  .main-content {
    width: 100%;

    h3 {
      font-size: 21px;
    }

    .detail-field-group {
      width: 100%;
      padding: 16px 0;
      p {
        margin: 0;
      }
      p:first-child {
        width: 20em;
        max-width: 160px;
        font-size: 12px;
        color: #a1a1a1;
        font-weight: 700;
      }
      p:nth-child(2) {
        width: 100%;
        white-space: pre-wrap;
      }
    }

    .other-details {
      width: 100%;
      .left-column,
      .right-column {
        width: 48%;
      }
    }

    .center-view-all-button {
      height: 17px;
      width: 138px;
      color: #db1e36;
      font-style: normal;
      font-weight: 800;
      font-size: 16px;
      margin-top: 20px;
    }
  }
}
