.create-edit-notification-container {
  padding: 1.5em;

  .form-content {
    flex-wrap: wrap;
  }
  h3 {
    margin: 0;
    padding-bottom: 1em;
    width: 100%;
    border-bottom: 1px solid #9797a8;
  }

  .full-width {
    width: 100%;
  }

  .half-width {
    width: 48%;
  }
  .quarter-width {
    width: 24%;
  }

  .static-input-label {
    font-size: 14px;
    opacity: 0.7;
  }

  .checkbox-label {
    margin-bottom: 0;
  }

  .bottom-buttons {
    width: 100%;
    margin-top: 2em;

    .right-side {
      flex: 1;
    }

    button {
      min-width: 120px;
      &:first-child {
        margin-right: 20px;
      }
    }
  }

  .mt-10 {
    margin-top: 10px;
  }
  .static-input-label {
    margin-bottom: 8px;
    margin-top: 24px;
    font-size: 14px;
    opacity: 0.7;
  }

  .checkbox-label {
    margin-right: 5px;
    margin-bottom: 0px;
  }
}
