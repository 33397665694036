#alert-list {
  .page-content {
    .table {
      height: 69vh;
      width: 97%;
      margin: auto;
    }
  }

  .table-buttons-and-filters {
    button {
      width: auto;
    }
  }
}