#fafsa-links-details {
  .card-background {
    padding: 20px;
  }
  h3 {
    font-size: 18px;
    margin: 0px;
  }

  .edit-button {
    padding: 0.5em 2em !important;
  }

  .details-container {
    margin-top: 30px;

    div.detail-section {
      margin-bottom: 36px;
      &:last-child {
        margin-bottom: 0;
      }

      h3.section-header {
        margin-bottom: 15px;
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
      }
    }

    span.info-span {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      margin-bottom: 20px;
      margin-right: 40px;
      width: 100%;
      word-break: break-word;

      p.details-title {
        margin: 0;
        margin-right: 40px;
        width: 130px;
        min-width: 130px;
        font-size: 12px;
        color: #a1a1a1;
        font-weight: 700;
        text-transform: uppercase;
      }
      p.details {
        margin: 0;
        font-size: 16px;
        font-weight: 400;
      }
    }
  }
}
