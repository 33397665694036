// Global styles for the app so we don't have to re use some classes over and over.
@import "./TableStyles.scss";
@import "./FormStyles.scss";
@import "./FilterStyles.scss";

// Display Flex Start
[class*="flex"] {
  display: flex;

  // Flex Direction Options
  &[class*="_col"] {
    flex-direction: column;
  }
  &[class*="_row"] {
    flex-direction: row;
  }

  // Justify Content Options
  &[class*="_jstart"] {
    justify-content: flex-start;
  }
  &[class*="_jcenter"] {
    justify-content: center;
  }
  &[class*="_jend"] {
    justify-content: flex-end;
  }

  // Justify Content Space Options
  &[class*="_jbetween"] {
    justify-content: space-between;
  }
  &[class*="_jaround"] {
    justify-content: space-around;
  }
  &[class*="_jeven"] {
    justify-content: space-evenly;
  }
  &[class*="_jstretch"] {
    justify-content: stretch;
  }

  // Align Items Options
  &[class*="_astart"] {
    align-items: flex-start;
  }
  &[class*="_acenter"] {
    align-items: center;
  }
  &[class*="_aend"] {
    align-items: flex-end;
  }

  // Other Flex Options
  &[class*="_wrap"] {
    flex-wrap: wrap;
  }
  &[class*="_wrapr"] {
    flex-wrap: wrap-reverse;
  }
  &[class*="_nwrap"] {
    flex-wrap: nowrap;
  }
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px white inset !important;
  box-shadow: 0 0 0 50px white inset !important;
}

.loading-indicator {
  height: 80vh;
}

.hidden {
  display: none !important;
}

.cursor-pointer {
  cursor: pointer;
}

.red-text {
  color: #db1e36 !important;
}

.card-content {
  padding: 0 12px;
  max-height: 260px;
  overflow: hidden;
}

.card-background {
  background-color: white;
  box-shadow: 0px 8px 10px rgba(181, 181, 181, 0.25);
  border-radius: 10px;
}

.main-page-tabs {
  margin-bottom: 25px;

  .MuiTab-root {
    height: 61px;
    font-size: 18px;
    text-transform: capitalize;
  }
  .MuiButtonBase-root {
    border-bottom: 2px solid #dedee6;
  }
  .Mui-selected {
    font-weight: bold;
  }
}

.table-buttons-and-filters {
  height: 50px;
  margin: 0 20px 10px 20px;

  .filters {
    .search {
      width: 497px;
      margin-right: 25px;
    }
    img {
      margin-right: 10px;
    }
    p {
      font-size: 16px;
      font-weight: 500;
    }
  }

  button {
    height: 48px;
    width: 150px;
    margin: 0 10px;
  }
  button:first-of-type {
    margin-left: 0;
  }
  button:last-of-type {
    margin-right: 0;
  }
}

.interactive-tooltip {
  font-size: 10px;
  color: #ffffff;
  background-color: #282828;
  border-radius: 4px;

  .arrow {
    position: absolute;
    top: 10px;
    margin-left: -0.9em;

    &::before {
      content: "";
      display: block;
      border-style: solid;
      border-width: 1em 1em 1em 0;
      border-color: transparent #282828 transparent transparent;
    }
  }

  .tooltip-content {
    width: 208px;
    margin: 10px 10px;

    h4 {
      margin: 0;
    }
    p {
      margin: 6px 0 10px 0;
    }

    .MuiButton-root {
      height: 20px;
      min-width: 50px;
      font-size: 10px;
      color: #ffffff;
      border-color: #ffffff;
      border: 1px solid;
      border-radius: 4px;
      text-transform: none;
    }
  }
}
