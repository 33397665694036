.step-three {
  height: 100%;
  .exisiting-users {
    padding-top: 1em;
  }
  .table {
    height: 48vh;
    width: 100%;
    margin: auto;
  }
  h3 {
    color: #2f2f51;
  }
  h4 {
    padding-top: 1em;
    text-align: center;
  }
}
