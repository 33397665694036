.update-password-success-dialog {
  h2 {
    text-align: center;
    margin-top: 20px;
  }

  .MuiPaper-root {
    width: 500px;

    p {
      text-align: center;
    }
  }

  .action-buttons {
    margin-top: 30px;

    button {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
}