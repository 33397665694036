.announcement-details{
  padding: 1.5em;

  .details-header {
    padding-bottom: 1em;
    border-bottom: 1px solid #9797A8;

    .back-button {
      cursor: pointer;
    }
    h3 {
      margin: 0;
    }
    button {
      padding: 0.25em 3em;
      border-width: 2px;
      border: 2px solid #db1e36;
    }
  }

  .main-content {
    width: 100%;

    h3 {
      font-size: 21px;
    }

    .left-column,
    .right-column {
      width: 48%;
    }

    .detail-field-group {
      width: 100%;
      padding: 16px 0;
      p {
        margin: 0;
      }
      p:first-child {
        width: 20em;
        max-width: 160px;
        font-size: 12px;
        color: #a1a1a1;
        font-weight: 700;
      }
      p:nth-child(2) {
        width: 100%;
        white-space: pre-wrap;
      }
    }
  }
}