#create-skill {
  padding: 20px;
  z-index: 0

  h3 {
    color: #2f2f51;
    padding-bottom: 1em;
    border-bottom: 1px solid #9797a8;
  }

  .half-width {
    width: 50%;
  }

  .active-checkbox {
    padding: 1.5em 0;
  }

  .buttons-wrapper {
    .right-side {
      flex: 1;
    }

    button {
      min-width: 120px;
      &:first-child {
        margin-right: 20px;
      }
    }
  }
}
