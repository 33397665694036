.master-key-submission-details {
  padding: 1.5em;
  .details-header {
    padding-bottom: 1em;
    border-bottom: 1px solid #efeff2;
    .back_arrow {
      cursor: pointer;
    }
    h3 {
      margin: 0;
    }
  }

  .main-content {
    width: 100%;

    .left-column,
    .right-column {
      width: 48%;
    }

    .detail-field-group {
      width: 100%;
      padding: 16px 0;
      p {
        margin: 0;

        &.answer {
          font-weight: bold;
        }
      }
      p:first-child {
        width: 160px;
        max-width: 160px;
        font-size: 12px;
        color: #a1a1a1;
        font-weight: 700;
      }
      p:nth-child(2) {
        width: 100%;
        white-space: pre-wrap;
      }
    }

    .question-content-wrapper {
      margin-top: 10px;

      .question-wrapper {
        border-top: 1px solid #EFEFF2;
        padding: 10px 0;
      }
    }
  }

  .buttons-wrapper {
    button {
      margin-right: 20px;
      &:last-child {
        margin-right: 0;
      }
    }

    .comments {
      padding-top: 5px;
      font-size: 10px;
      color: #282828;
    }
  }
}