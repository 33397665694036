#career-cluster-list {
  .list-header {
    padding: 20px 20px 10px 20px;

    .back-button {
      width: fit-content;
      cursor: pointer;
    }
    h3 {
      margin: 0;
    }
    p {
      margin-bottom: 0;
      font-size: 14px;
      font-weight: 500;
    }
  }

  .page-content {
    height: 85vh;
    .table {
      padding: 0px 30px !important;
    };
  }

  h4 {
    padding: 0 30px;
    color: #a1a1a1;
    font-size: 14px;
  }

  .main-list {
    height: 100%;
    margin: 0px 10px;
    overflow: auto;

    .career-group-list-item {
      padding: 1em 30px;
      border-bottom: 1px solid #efeff2;
      &:hover {
        background-color: #f1f1ff;
      }
    }
  }
}
