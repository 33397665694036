.course-table {
  .data-wrapper {
    position: relative;
  }

  .data-row {
    padding-bottom: 7px;
    margin-bottom: 7px;

    border-bottom: 1px solid #EFEFF2;

    &.header {
      display: flex;

      .data-cell {
        .data-label {
          display: block;
        }

        .data-content {
          display: none;
        }
      }
    }

    .data-cell {
      &.name {
        width: 25%;
      }

      &.link {
        flex-grow: 1;
        width: 30%;

        p {
          margin: 0;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      &.credits {
        width: 10%;
      }

      &.concurrent-enrollment {
        width: 15%;
      }

      &.recommended {
        width: 15%;
      }

      &.active {
        width: 3%;
      }

      .data-label {
        display: none;
        margin-right: 10px;

        text-transform: uppercase;
        color: #a1a1a1;
        font-size: 12px;
        font-weight: bold;
      }

      .data-content {
        margin-right: 20px;
        color: black;
        font-size: 14px;
        font-weight: normal;
      }

      .eye-icon {
        height: 15px;
      }
    }

    @media screen and (max-width: 899px) {
      flex-direction: column;
      align-items: flex-start;

      padding: 10px;

      &.header {
        display: none !important;
      }

      .data-cell {
        margin-bottom: 12px;

        width: 100% !important;

        .data-label {
          display: block;
          margin-bottom: 3px;
        }
      }

      .active {
        @media (max-width: 900px) {
          position: absolute;
          text-align: right;

          .data-content {
            margin: 0;
          }
        }
      }
    }
  }
}
