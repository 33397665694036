#navigation-menu {
  height: 100vh;
  max-height: 100vh;
  display: flex;

  .drawer-header {
    height: 44px;
    position: relative;
    padding-top: 1em;
    margin-bottom: 1em;
    .img {
      color: white;
    }
  }

  .white-out {
    * {
      color: white;
    }
  }

  .content {
    padding: 1.5em;
    margin-left: 74px;
    width: 100%;
    background: #e5e5e58a;
    overflow: auto;

    .page-header {
      margin-bottom: 21px;

      h2 {
        width: 100%;
        margin: 0;
        margin-left: 10px;
        font-size: 24px;
        font-weight: bold;
        line-height: 29px;
        color: #2F2F51;
      }
    }
  }
}

#navigation-menu .nav-drawer {
  z-index: 1;
  position: absolute;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  ::-webkit-scrollbar {
    display: none;
  }
}

.nav-header {
  padding-left: 24px !important;
  white-space: nowrap;
  height: 3em;
  z-index: 999;
  .MuiListItemText-primary {
    font-weight: 800;
  }

  &:hover {
    :after {
      content: "";
      position: absolute;
      top: 25%;
      right: 0px;
      width: 0;
      height: 0;
      border-top: 12px solid transparent;
      border-bottom: 12px solid transparent;
      border-right: 12px solid #444462;
    }
  }
}

.MuiListItemText-inset {
  margin-left: 0.25em;
  padding-top: 4px;
  padding-bottom: 4px;
  white-space: nowrap;
}

.MuiListItemIcon-root {
  min-width: 45px !important;
  overflow: hidden;
}

.toggle-button {
  color: #2f2f51;
  position: absolute;
  z-index: 60;
  top: calc((1em + 2px));
  background: white;
  border-radius: 3px;
  cursor: pointer;
  box-shadow: 0px 0px 8px rgba(47, 47, 81, 0.5);

  &.open {
    left: 228px;
    transform: rotate(180deg);
  }

  &.closed {
    left: 65px;
  }
  transition: all 250ms cubic-bezier(0, 0, 0.2, 1) 0ms !important;
}

.MuiDrawer-paper {
  transition: width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms !important;
}

.selected-item {
  background-color: rgba(109, 109, 133, 0.5) !important;
}

.hide-item {
  opacity: 0;
}

.nav-menu {
  z-index: 42;
  pointer-events: none;
}

.nav-menu-button {
  background-color: #444462 !important;
  color: white !important;
  position: relative;
  &:hover {
    background-color: rgba(109, 109, 133, 0.5) !important;
  }
}

.header-button {
  border-bottom: 1px solid #9d9db7 !important;
}

.link-icon {
  padding-right: 25px;
  padding-left: 5px;
  height: 1em;
  width: 1em;
}

.sublink-icon {
  padding-right: 5px;
}

.MuiMenu-paper {
  pointer-events: all !important;
}
