@import "./DashboardCard.scss";

#exports-card {
  height: 100%;
  width: 100%;
  .card-header {
    h3 {
      margin: 0;
      margin-left: 0.5em;
    }
  }
  .tab-content {
    text-align: center;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.5em;
    padding: 10px 20px;
    height: 100%;
  }
  .export-menu {
    background-color: #b9519e;
    border-radius: 6px;
    padding: 6px;
    text-decoration: none;
    color: white;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    cursor: pointer;
    height: 3em;
  }
}
.menu-button {
  width: 100%;
  padding: 6px 16px;
  text-decoration: none;
  color: black;
}
