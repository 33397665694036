@import "./DashboardCard.scss";

#admin-announcement-banner {
  position: relative;
  min-height: 111px;
  width: 100%;
  margin: 8px 0;

  .megaphone-icon {
    width: 87px;
    border-radius: 10px 0 0 10px;
    background-color: #DB1E36;
  }

  .announcement-content {
    margin: 12px 65px 12px 32px;

    h3 {
      margin: 0;
      font-size: 18px;
    }
    p {
      margin: 6px 0 0 0;
      font-size: 14px;
      line-height: 20px;
    }
    a {
      margin: 2px 0 0 0;
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;
      text-decoration: none;
      color: black;
    }
  }

  .close-icon {
    position: absolute;
    top: 14px;
    right: 12px;
    height: 20px;
    width: 20px;
    cursor: pointer;
  }
}
