#bulk-account-create-wrapper {
  flex: 1;
  background-color: white;
  box-shadow: 0px 10px 50px rgba(181, 181, 181, 0.5);
  // This makes it so that the page can touch the edges as the design does
  margin-left: -24px;
  margin-right: -24px;
  padding: 30px 50px;
  z-index: 2;
  margin-bottom: -63px;
  position: relative;
  @media screen and (min-width: 1921px) {
    font-size: 20px;
  }
}
.content {
  overflow: hidden;
}

.selected-school {
  position: absolute;
  height: 56px;
  right: 0;
  top: 15px;
  padding: 10px 20px;
  border-radius: 16px 0 0 16px;
  background: #ececff;
  box-shadow: 2px 6px 8px rgba(70, 70, 70, 0.25);
  b {
    margin-right: 0.5em;
  }
}
h1 {
  margin: 0;
  color: red;
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
}
p {
  color: #282828;
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  line-height: 145%;
}
b {
  line-height: 145%;
}

.bulk-create-stepper {
  height: 4vh;
  margin: 2em 0 2em 0;
  max-width: 2200px;
}

.responsive-container {
  height: 100%;
  width: 100%;
  max-height: 1440px;
  max-width: 2200px;
  align-self: center;
}

.content-wrapper {
  flex: 1;
  width: 100%;
  max-width: 2200px;

  .main-content {
    height: 100%;
  }
}

.stepper-nav {
  width: 100%;
  max-width: 2200px;
  align-self: center;
  button {
    height: 3em;
    width: 9em;
  }

  .edit-mode {
    margin-right: 1em;
  }

  .pending-validation {
    margin-right: 4em;
    font-size: 12px;
    font-weight: 700;
    color: #f7553f;
  }
}
