.opportunity-details{
  padding: 1.5em;
  .details-header {
    padding-bottom: 1em;
    h3 {
      margin: 0;
    }
    .back_arrow {
      cursor: pointer;
    }
    button {
      padding: 0.25em 3em;
      border-width: 2px;
      border: 2px solid #db1e36;
    }
    .updated-banner {
      .updated-text {
        color: #db1e36;
      }
      .updated-by-text{
        font-weight: 500;
        color: #A1A1A1;
      }
      padding-right: 1.5em;
      font-size: 12px;
    }
    border-bottom: 1px solid #efeff2;
  }

  .main-content {
    width: 100%;

    .view-all {
      cursor: pointer;
      div {
        padding: 0.5em 1em;
        margin-right: 1em;
        border-radius: 4px;
        background: #db1e36;

        background-color: #db1e36;
        color: white;
        font-weight: 700;
      }
      p {
        color: #db1e36;
        font-weight: 700;
        margin: 0;
      }
    }

    .other-details {
      width: 100%;
      margin-top: 0.5em;
      .left-column,
      .right-column {
        width: 48%;
      }
    }

    span {
      margin-top: 16px;
      margin-bottom: 16px;

      // Flex is here to reduce the amount of times it's written in the ts file.
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      p {
        margin: 0;
      }
      p:first-child {
        width: 20em;
        max-width: 160px;
        font-size: 12px;
        color: #a1a1a1;
        font-weight: 700;
      }
      p:nth-child(2) {
        width: 100%;
      }
    }

    .center-view-all-button{
      height: 17px;
      width: 138px;
      color: #DB1E36;
      font-style: normal;
      font-weight: 800;
      font-size: 16px;
      margin-top: 20px;
    }
  }
}
