#feature-list {
  .page-content {
    .table {
      height: 69vh;
      width: 97%;
      margin: auto;
    }
  }

  .table-buttons-and-filters {
    .MuiButton-root {
      width: auto;
      font-size: 14px;
    }
  }
}
