.required-field:after {
  content: " *";
  color: red;
}

.form-input {
  width: 100%;
}

.disabled-label {
  color: rgba(0, 0, 0, 0.38);
}

.disabled-input {
  background-color: #E8E8E8;
  border-radius: 8px;
}

.info-icon {
  margin-left: 10px;
}

// This class is set up to mimic the default MUI error message, minus color
.helper-message {
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  text-align: left;
  margin: 4px 14px 0 14px !important;
}

.error-message {
  color: #d32f2f !important;
}
