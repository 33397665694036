#list-filter {
  .MuiPopover-paper {
    max-height: 520px;
    width: 265px;
    padding: 18px 20px 18px 20px;
  }

  .close-icon {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 42;
    cursor: pointer;
  }

  .MuiFormLabel-root {
    font-size: 14px;
    font-weight: bold;
    color: black;
  }

  .submit-button {
    width: 100%;
    margin-top: 10px;
  }
}
