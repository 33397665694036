.change-password-dialog {
  h2 {
    text-align: center;
    margin-top: 20px;
  }

  form {
    width: 500px;

    .action-buttons {
      margin-top: 30px;
    }
  }
}