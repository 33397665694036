#approval-list {
  .page-content {
    .table {
      height: 69vh;
      width: 97%;
      margin: auto;
    }
  }
  .table-buttons-and-filters {
    button {
      width: auto;
    }
  }

  .select-all-container {
    height: 48px;
    width: fit-content;
    margin-left: 20px;
    padding: 0 15px;
    background-color: #f7f7f9;
    border-radius: 8px;

    p {
      margin-right: 15px;
      font-weight: 500;
    }
    button {
      height: 34px;
      &:first-of-type {
        margin-right: 1em;
      }
      &:nth-child(2) {
        background-color: #19b268 !important;
      }
    }
  }
  .no-more-approvals {
    height: 100%;
  }
}
