@import "./DashboardCard.scss";

#scholarship-applications-card {
  height: 100%;
  max-height: 500px;

  .school-header {
    width: 40%;

    p {
      margin-right: 5px;
    }

    img {
      width: 13px;
      height: 13px;
      margin-top: 1.1em;
    }
  }

  .width-30 {
    width: 30%;
    text-align: center;
  }

  .column-data {
    padding: 0 15px;

    p {
      font-weight: bold;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    p.school {
      width: 40%;
      color: #444462;
    }
    p.need-review {
      width: 30%;
      font-size: 16px;
      text-align: center;
      color: #70449b;
    }
    p.new {
      width: 30%;
      font-size: 16px;
      text-align: center;
      color: #93d500;
    }
  }
}
