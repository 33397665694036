.landing-step {
  max-width: 2200px;
  margin-left: auto;
  margin-right: auto;
  img {
    height: 90%;
    width: 90%;
  }
  .tutorial-video {
    height: 440px;
    width: 100%;
    max-width: 790px;
    background: #ffffff;
    box-shadow: 0px 4px 10px 4px rgba(134, 134, 134, 0.25);
    border-radius: 20px;
    position: relative;
    margin-bottom: 4em;
    overflow: hidden;

    video {
      height: 100%;
      width: 100%;
    }
  }

  p {
    width: 100%;
    max-width: 1028px;
    margin-bottom: 4em;
  }
}
