#resource-application-list {


  .page-content {
    .table {
      height: 69vh;
      width: 97%;
      margin: auto;
    }
  }

  .table-buttons-and-filters{
    button{
      width: auto;
    }
  }

  h2 {
    margin: left;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    color: #2F2F51;
    padding: 15px;
  }

  hr {
    border: 1px solid #EFEFF2
  }


  .resource-title-and-applicants {

    padding-bottom:10px;
    text-align:center;

    .resource-title {
      float: left;
      font-style: normal;
      font-weight: bold;
      font-size: 21px;
      padding: 15px;
      color: #282828;
    }
    
    .applicant-count {
      float: right;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      color: #2F2F51;
      padding: 15px;
    }
  }

}
