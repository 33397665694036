.MuiTableHead-root .MuiTableRow-root .MuiTableCell-head {
  font-weight: bold;
  resize: horizontal;
  overflow-x: overlay;
  overflow-y: hidden;
  border-right: 1px solid;
}

.MuiDataGrid-columnSeparator {
  display: none !important;
}
.MuiDataGrid-columnHeaderTitle {
  font-size: 14px;
  font-weight: bold;
  color: #a1a1a1;
  text-transform: uppercase;
}
.MuiDataGrid-columnHeaderTitleContainer {
  padding: 0 !important;
}

.MuiDataGrid-row {
  cursor: pointer;
}

.MuiDataGrid-cell {
  font-size: 14px;
}

.MuiDataGrid-root {
  border: none !important;
}
.MuiDataGrid-columnHeader:focus {
  outline: none !important;
}
.MuiDataGrid-cell:focus {
  outline: none !important;
}
.MuiDataGrid-columnHeader:focus-within {
  outline: none !important;
}
.MuiDataGrid-cell:focus-within {
  outline: none !important;
}

.MuiDataGrid-footerContainer {
  justify-content: center !important;
  min-height: fit-content !important;
  padding-top: 15px;
  padding-bottom: 15px;
}

.main-column {
  font-weight: bold;
}

.org-column {
  text-transform: capitalize;
}

.table-update-icon {
  margin-left: 15px;
}

.row-count {
  position: relative;
  bottom: 18px;
  left: 20px;
  margin: 0;
  width: fit-content;
  font-size: 14px;
  color: #656565;
}

.link-action-column {
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  text-decoration: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.45);
}

.user-list {
  .MuiDataGrid-columnHeadersInner:before {
    content: "";
    width: 5px;
    height: auto;
  }
  .MuiDataGrid-row:before {
    content: "";
    width: 5px;
    height: auto;
  }
}

.high-school-row {
  &.MuiDataGrid-row:before {
    background-color: blue;
  }
}
.middle-school-row {
  &.MuiDataGrid-row:before {
    background-color: orange;
  }
}
.adult-school-row {
  &.MuiDataGrid-row:before {
    background-color: purple;
  }
}

.filter-button {
  cursor: pointer;
  height: 100%;

  p {
    margin: 0;
  }
}
