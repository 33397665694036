.user-applications-table {
  .applications-data-row {
    justify-content: stretch;
    align-items: center;
    padding-bottom: 7px;
    margin-bottom: 7px;

    border-bottom: 1px solid #EFEFF2;

    &.header {
      display: flex!important;

      .applications-data-cell {
        .applications-data-label {
          display: block!important;
        }

        .applications-data-content {
          display: none!important;
        }
      }
    }

    .applications-data-cell {
      &:first-child {
        width: 40%;
      }

      &:nth-child(2) {
        flex-grow: 1;
      }

      &:nth-child(3) {
        width: 25%;
      }

      .applications-data-label {
        display: none;

        text-transform: uppercase;
        color: #a1a1a1;
        font-size: 12px;
        font-weight: bold;
      }

      .applications-data-content {
        color: black;
        font-size: 14px;
        font-weight: normal;

        &.org-data {
          text-transform: capitalize;
        }
      }
    }

    @media screen and (max-width: 899px) {
      flex-direction: column;
      align-items: flex-start;

      padding: 10px;

      &.header {
        display: none!important;
      }

      .applications-data-cell {
        margin-bottom: 12px;

        width: 100%!important;

        .applications-data-label {
          display: block;
          margin-bottom: 3px;
        }
      }
    }
  }
}
