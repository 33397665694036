#edit-career-cluster {
  .card-background {
    padding: 20px;
  }
  h3 {
    font-size: 18px;
    margin: 0px;
  }
  h4 {
    font-size: 16px;
  }

  .content-header {
    padding-bottom: 1em;
    border-bottom: 1px solid #efeff2;
  }
  
  .skills-container {
    .skills-header {
      font-size: 12px;
      color: #a1a1a1;
    }
  
    .skill-content {
      width: 100%;
  
      .skill-input {
        width: 50%;
        margin-right: 3em;
      }
   
    }
  }
  
  .button-container{
    margin-top: 2em;
  }
  
  .width-100{
    width: 100%;
  }
  
  .buttons-wrapper {
    button {
      min-width: 120px;

      &:first-child {
        margin-right: 20px;
      }
    }
  }
}

