#create-edit-masterkey {
  .card-background {
    padding: 20px;
  }
  h3 {
    font-size: 18px;
    margin: 0px;
  }
  h4 {
    font-size: 16px;
  }

  .masterkey-content-header {
    padding-bottom: 1.5em;
    border-bottom: 1px solid #efeff2;
  }

  .half-width {
    width: 45%;
  }

  .questions-container {
    width: 100%;
  }

  .buttons-container {
    width: 100%;
    margin-top: 1.5em;

    .right-side {
      flex: 1;
    }

    button {
      min-width: 120px;
      &:first-child {
        margin-right: 20px;
      }
    }
  }

  .divider {
    margin: 15px 0;
    border-bottom: 1px solid #9797a8;
  }
}
