#duplicate-resource-modal {

  .modal-content {
    width: 675px;
    min-height: 360px;
  }

  h2 {
    font-size: 21px;
    color: #DB1E36;
    text-align: center;
    margin: 47px 0 25px 0;
  }

  .warning-text {
    width: 70%;
    text-align: center;
  }

  .resource-info {
    width: 85%;
    min-height: 50px;
    margin-bottom: 15px;
    background-color: #E3E3F0;
    border-radius: 6px;

    .info-box {
      max-width: 80%;
      margin: 16px 20px;
      font-size: 14px;
      font-weight: 400;

      p {
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .link-box {
      max-width: 20%;
      padding: 0 20px;
      font-weight: 500;
      color: #FFFFFF;
      text-decoration: none;
      background-color: #2F2F51;
      border-radius: 0 6px 6px 0;
      cursor: pointer;
    }

    .link-icon {
      width: 16px;
      height: 16px;
      margin-left: 13px;
      padding: 0;
    }
  }

  .dismiss-button {
    margin: 15px 0 50px 0;
  }
}
