@import "../components/dashboard/DashboardCard.scss";

.dashboard-main {
  display: flex;
  flex-direction: column;

  h1 {
    color: red;
    font-weight: 500;
    font-size: 40px;
    margin-top: 0;
  }

  .page-content {
    height: 100%;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 16px;
  }
  .admin-dashboard {
    .approval-summary {
      grid-column: 1 / span 4;
      grid-row: 1 / span 5;
      min-height: 0;
      min-width: 0;
    }
    .current-announcements {
      grid-column: 1 / span 4;
      grid-row: 6 / span 5;
      min-height: 0;
      min-width: 0;
    }
    .expiring-resources {
      grid-column: 5 / span 5;
      grid-row: 1 / span 3;
      min-height: 0;
      min-width: 0;
    }
    .scholarship-apps {
      grid-column: 5 / span 5;
      grid-row: 4 / span 7;
      min-height: 0;
      min-width: 0;
    }
    .quick-links {
      grid-column: 10 / span 3;
      grid-row: 1 / span 3;
      min-height: 0;
      min-width: 0;
    }
    .exports {
      grid-column: 10 / span 3;
      grid-row: 4 / span 4;
      min-height: 0;
      min-width: 0;
    }
    .master-key {
      grid-column: 10 / span 3;
      grid-row: 8 / span 3;
      min-height: 0;
      min-width: 0;
    }

    .people-at-desk,
    .resource-applicants {
      display: none;
    }
  }

  .district-institution-admin-dashboard {
    .approval-summary {
      grid-column: 1 / span 4;
      grid-row: 1 / span 3;
      min-height: 0;
      min-width: 0;
    }
    .expiring-resources {
      grid-column: 1 / span 4;
      grid-row: 4 / span 3;
      min-height: 0;
      min-width: 0;
    }
    .resource-applicants {
      grid-column: 5 / span 5;
      grid-row: 1 / span 5;
      min-height: 0;
      min-width: 0;
    }
    .quick-links {
      grid-column: 10 / span 3;
      grid-row: 1 / span 3;
      min-height: 0;
      min-width: 0;
    }
    .exports {
      grid-column: 10 / span 3;
      grid-row: 4 / span 4;
      min-height: 0;
      min-width: 0;
    }

    .people-at-desk {
      display: none;
    }

    .current-announcements,
    .scholarship-apps,
    .master-key {
      display: none;
    }
  }

  .district-admin-dashboard {
    .approval-summary {
      grid-column: 1 / span 4;
      grid-row: 1 / span 3;
      min-height: 0;
      min-width: 0;
    }
    .resource-applicants {
      grid-column: 1 / span 4;
      grid-row: 4 / span 5;
      min-height: 0;
      min-width: 0;
    }
    .expiring-resources {
      grid-column: 5 / span 5;
      grid-row: 1 / span 3;
      min-height: 0;
      min-width: 0;
    }
    .scholarship-apps {
      grid-column: 5 / span 5;
      grid-row: 4 / span 7;
      min-height: 0;
      min-width: 0;
    }
    .quick-links {
      grid-column: 10 / span 3;
      grid-row: 1 / span 3;
      min-height: 0;
      min-width: 0;
    }
    .exports {
      grid-column: 10 / span 3;
      grid-row: 4 / span 4;
      min-height: 0;
      min-width: 0;
    }

    .people-at-desk {
      display: none;
    }

    .current-announcements,
    .master-key {
      display: none;
    }
  }

  .student-key-contact-dashboard {
    .approval-summary {
      grid-column: auto / span 4;
      grid-row: 1 / span 3;
      min-height: 0;
      min-width: 0;
      z-index: 1;
    }
    .expiring-resources {
      grid-column: auto / span 5;
      grid-row: 1 / span 5;
      min-height: 0;
      min-width: 0;
      z-index: 1;
    }

    .quick-links {
      grid-column: auto / span 3;
      grid-row: 1 / span 4;
      min-height: 0;
      min-width: 0;
      z-index: 1;
    }
    .exports {
      grid-column: 10 / span 3;
      grid-row: 5 / span 4;
      min-height: 0;
      min-width: 0;
      z-index: 1;
    }

    .people-at-desk {
      height: 20em;
      width: 30em;
      position: absolute;
      bottom: 0;
      z-index: 0;
    }

    .current-announcements,
    .resource-applicants,
    .scholarship-apps,
    .master-key {
      display: none;
    }
  }

  .counselor-dashboard,
  .adult-key-contact-dashboard,
  .higher-key-contact-dashboard {
    .expiring-resources {
      grid-column: auto / span 6;
      grid-row: 1 / span 5;
      min-height: 0;
      min-width: 0;
      z-index: 1;
    }

    .quick-links {
      grid-column: auto / span 3;
      grid-row: 1 / span 4;
      min-height: 0;
      min-width: 0;
      z-index: 1;
    }
    .exports {
      grid-column: auto / span 3;
      grid-row: 1 / span 2;
      min-height: 0;
      min-width: 0;
      z-index: 1;
    }

    .people-at-desk {
      height: 20em;
      width: 30em;
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 0;
    }

    .approval-summary,
    .current-announcements,
    .resource-applicants,
    .scholarship-apps,
    .master-key {
      display: none;
    }
  }

  .community-dashboard,
  .institution-dashboard {
    .resource-applicants {
      grid-column: 1 / span 4;
      grid-row: 1 / span 5;
      min-height: 0;
      min-width: 0;
      z-index: 1;
    }

    .expiring-resources {
      grid-column: 5 / span 5;
      grid-row: 1 / span 3;
      min-height: 0;
      min-width: 0;
      z-index: 1;
    }

    .quick-links {
      grid-column: 10 / span 3;
      grid-row: 1 / span 3;
      min-height: 0;
      min-width: 0;
      z-index: 1;
    }
    .exports {
      grid-column: 10 / span 3;
      grid-row: 4 / span 4;
      min-height: 0;
      min-width: 0;
      z-index: 1;
    }

    .people-at-desk {
      height: 20em;
      width: 30em;
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 0;
    }

    .approval-summary,
    .current-announcements,
    .scholarship-apps,
    .master-key {
      display: none;
    }
  }
}
