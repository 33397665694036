.user-overview {
  .overview-str {
    text-transform: capitalize;
  }

  .overview-summary {
    width: 100%;

    @media print {
      display: none;
    }

    .back_arrow {
      cursor: pointer;
    }

    .generate-code {
      margin-left: 30px;

      p {
        color: #db1e36;
        margin-right: 0.5em;
        font-weight: 700;
        cursor: pointer;
      }
      .code-tooltip {
        height: 16px;
        width: 16px;
      }
    }

    .approval-actions-wrapper {
      height: 100%;
      background: #ffe2de;
      border-radius: 8px;
      padding: 0 20px;
      font-size: 14px;

      .approval-img {
        margin-left: 20px;
        cursor: pointer;
      }

      .reject-img {
        margin-left: 10px;
        cursor: pointer;
      }
    }

    .deleted-info {
      font-size: 14px;
      font-weight: 700;
      color: #db1e36;

      p {
        margin: 0;
      }

      .restore-user-btn {
        width: 140px;
        margin-left: 20px;

        @media screen and (max-width: 750px) {
          margin-left: 10px;
        }
      }
    }

    .transfer-info {
      background: #efeff2;
      margin-top: 10px;
      padding: 15px 20px;
      border-radius: 8px;
      font-weight: normal;
      font-size: 14px;

      img {
        padding-right: 12px;
      }

      span {
        padding-right: 20px;

        &.split-line {
          color: #db1e36;
        }
      }
    }
  }

  .overview-header-wrapper {
    justify-content: space-between;
    align-items: center;

    .right-side {
      font-size: 10px;
      font-weight: 500;
      width: 250px;
    }

    @media screen and (max-width: 750px) {
      flex-direction: column;
      align-items: flex-start;

      .left-side,
      .right-side {
        margin-bottom: 30px;
      }
    }
  }
}

.MuiTooltip-popper {
  * {
    font-size: 14px !important;
  }
}
