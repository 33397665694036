#upload-button {
  display: flex;

  .uploaded-file {
    display: flex;
    align-items: center;

    span {
      margin: 0 15px 0 0px;
      padding: 15px 18px;
      background: #EFEFF2;
      border-radius: 8px;
      margin-right: 15px;
    }
    .cancel-icon {
      cursor: pointer;
      width: 20px;
    }
  }
}
