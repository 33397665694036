#create-college-checklist-form-container {
  padding: 20px;
  z-index: 0;

  .form-content {
    flex-wrap: wrap;
  }

  .list-header {
    padding-bottom: 10px;

    .back-button {
      cursor: pointer;
    }
    h3 {
      margin: 0;
      color: #2f2f51;
    }
    p {
      margin-bottom: 0;
    }
  }

  .form-content {
    border-top: 1px solid #efeff2;
  }

  .full-width {
    width: 100%;
  }
  .half-width {
    width: 45%;
  }
  .quarter-width {
    width: 24%;
  }

  .width-100 {
    width: 100%;
  }
  .width-75 {
    width: 75%;
  }
  .width-45 {
    width: 45%;
  }
  .width-25 {
    width: 25%;
  }

  .delete-option-icon {
    padding-top: 49px;
    height: 40px;
    width: 20px;
    margin-right: 1em;
  }

  section.form-section {
    h4 {
      margin: 3.5em 0em 0.5em 0em;
      &:nth-child(1) {
        margin-top: 0.75em;
      }
    }
  }

  .static-input-label {
    margin-bottom: 8px;
    margin-top: 24px;
    font-size: 14px;
    opacity: 0.7;
  }

  .checkbox-label {
    margin-right: 5px;
    margin-bottom: 0px;
  }
}

.logo-button-container {
  margin: 20px 0px 50px 0;
}

.form-button-wide {
  width: 150px;
}
