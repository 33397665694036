#resource-tag-form {
  height: 100%;
  z-index: 0;

  .card-background {
    padding: 20px;
  }

  h3 {
    color: #2f2f51;
    padding-bottom: 1em;
    border-bottom: 1px solid #9797a8;
    margin-top: 0.5em;
  }

  .half-width {
    width: 50%;
  }

  .buttons-wrapper {
    margin-top: 1em;
    .right-side {
      flex: 1;
    }

    button {
      min-width: 120px;
      &:first-child {
        margin-right: 20px;
      }
    }
  }


  .description-count{
    font-size: 10px;
    color: gray;
    margin: 8px
  }
}
