#college-details {
  .card-background {
    padding: 20px;
  }
  .list-header {
    padding-bottom: 10px;

    .back-button {
      cursor: pointer;
    }
    h3 {
      margin: 0;
    }
    p {
      margin-bottom: 0;
    }
  }

  .details-content {
    border-top: 1px solid #efeff2;
    padding: 0 12px;
    p:first-child {
      color: #a1a1a1;
      font-size: 12px;
      font-weight: 700;
      margin-bottom: 0;
    }
    p:nth-child(2) {
      margin-left: 10em;
      margin-bottom: 0;
    }
  }

  .top-container {
    form {
      width: 50%;
    }

    .search {
      width: 100%;
    }
  }

  .MuiButton-root {
    width: 9em;
  }

  section {
    padding-bottom: 16px;

    span.info-span {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      margin-bottom: 20px;
      width: 48%;
      word-break: break-word;
      p:first-child {
        margin: 0;
        width: 13em;
        min-width: 13em;
        margin-right: 5em;
        font-size: 12px;
        color: #a1a1a1;
        font-weight: 700;
      }
      p:nth-child(2) {
        margin: 0;
        font-size: 16px;
        font-weight: 400;
      }
    }
  }

  .top-section {
    div {
      width: 50%;
    }
    span.info-span {
      width: 100%;
    }

    .img-container {
      padding-left: 10vh;
      img {
        height: 10vh;
        width: 15vh;
        min-height: 200px;
        min-width: 250px;
      }
    }
  }
}
