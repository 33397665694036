.step-five {
  height: 100%;
  width: 90%;
  max-width: 2200px;
  margin-left: auto;
  margin-right: auto;

  .divider {
    height: 100%;
    width: 1px;
    background-color: gray;
  }

  .left-container {
    width: 45%;
    p {
      color: #282828;
      font-style: normal;
      font-weight: 400;
      font-size: 1em;
      line-height: 145%;
    }
  }

  .access-code {
    .code {
      font-weight: 700;
      font-size: 40px;
      padding: 10px 24px;
      border-radius: 8px;
      margin-right: 1em;
      letter-spacing: 8px;
      background-color: #f6f6fb;
    }

    .forgotten-code {
      width: 100%;
      max-width: 270px;
      font-weight: 400;
      font-size: 16px;
      b {
        font-weight: 700;
      }
    }
  }

  .right-container {
    width: 40%;

    i {
      padding-left: 8em;
      font-weight: 700;
    }

    .example-img {
      margin-top: 2em;
      width: 100%;
      max-width: 600px;
      background: #ffffff;
      border-radius: 8px;
    }
  }

  .red-text {
    color: red;
  }
}

h3 {
  font-size: 18px;
  line-height: 22px;
}
