#skill-training-details {
  .card-background {
    padding: 20px;
    min-height: 70vh;
  }
  .content-header {
    padding-bottom: 1em;
    border-bottom: 1px solid #efeff2;

    .back-button {
      cursor: pointer;
    }
    h3 {
      margin: 0;
    }
    p {
      margin-bottom: 0;
    }

    .status-icon {
      margin-right: 4em;
    }
    .awaiting-approval {
      background-color: #ffe2de;
      padding: 12px 20px;
      margin-right: 20px;
      border-radius: 8px;

      p {
        margin: 0;
        font-weight: 700;
        font-size: 14px;
      }
    }
  }

  .main-content {
    flex-wrap: wrap;
    height: 100%;
    padding-top: 1em;
    .left {
      width: 50%;
      height: 100%;

      span {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 1.5em;
        p {
          margin: 0;
        }
        :nth-child(1) {
          font-size: 12px;
          font-weight: 700;
          color: #a1a1a1;
          min-width: 12em;
          margin-right: 40px;
        }
        :nth-child(2) {
          font-size: 16px;
          font-weight: 400;
          color: #282828;
          width: 100%;
        }
      }
    }

    .right {
      width: 50%;
      height: 100%;

      .skilltraining-image {
        min-height: 10em;
        min-height: 10em;
        max-height: 20em;
        max-width: 20em;
      }
    }
  }
}
