.step-two {
  height: 100%;
  max-width: 2200px;
  margin-left: auto;
  margin-right: auto;

  .csv-input-uploader {
    height: 2em;
  }

  .csv-progress-bar {
    bottom: 14px;
    position: "absolute";
    width: "100%";
    padding-left: 10px;
    padding-right: 10px;
  }
  .left-container {
    width: 40%;

    ol {
      b {
        color: var(--color-lipstick);
        border-bottom: 2px solid var(--color-lipstick);
        cursor: pointer;
      }
    }

    .school-name {
      width: 600px;
      b {
        margin-right: 0.5em;
      }
    }

    .csv-uploader {
      cursor: pointer;
      background: #f6f6fb;
      border-radius: 8px;
      padding: 2em;
      margin-top: 5em;
      position: relative;
      height: 280px;

      b {
        font-weight: 500;
      }
      img,
      b,
      p {
        margin: 0 0 12px 0;
      }
      .upload-or {
        position: relative;
        &::before {
          content: "";
          height: 1px;
          background-color: var(--color-dark-d-05);
          width: 65px;
          position: absolute;
          left: calc(100% + 10px);
          top: calc(50% - 1px);
        }
        &::after {
          content: "";
          height: 1px;
          background-color: var(--color-dark-d-05);
          width: 65px;
          position: absolute;
          right: calc(100% + 10px);
          top: calc(50% - 1px);
        }
      }

      button {
        background-color: var(--color-dark-d-05);
        width: 100%;
      }
    }

    .upload-in-progress {
      img {
        margin-right: 0.5em;
      }
      p,
      h4 {
        margin: 0;
      }
      p {
        font-size: 12px;
        color: #a1a1a1;
      }
      h4 {
        color: #282828;
      }
      .try-again {
        color: red;
      }
    }

    .csv-uploader {
      max-width: 600px;
      max-height: 200px;
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23333' stroke-width='3' stroke-dasharray='7%2c 20' stroke-dashoffset='15' stroke-linecap='square'/%3e%3c/svg%3e");
    }
  }
  .divider {
    height: 100%;
    width: 1px;
    background-color: gray;
  }
  .right-container {
    width: 40%;

    .example-img {
      margin-top: 2em;
      width: 100%;
      max-width: 600px;
      background: #ffffff;
      border-radius: 8px;
    }
  }

  li,
  b {
    font-size: 1em;
    line-height: 1.25em;
  }
}
