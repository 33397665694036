.user-notifications-table {
  .notifications-data-row {
    justify-content: stretch;
    align-items: center;
    padding-bottom: 7px;
    margin-bottom: 7px;

    border-bottom: 1px solid #EFEFF2;

    &.header {
      display: flex;

      .notifications-data-cell {
        .notifications-data-label {
          display: block;
        }

        .notifications-data-content {
          display: none;
        }
      }
    }

    .notifications-data-cell {
      &:first-child {
        width: 24%;
        padding-right: 20px;
      }

      &:nth-child(2) {
        flex-grow: 1;
        width: 40%;
        padding-right: 20px;
      }

      &:nth-child(3) {
        width: 150px;
        text-overflow: ellipsis;
      }

      &:nth-child(4) {
        width: 150px;
        text-overflow: ellipsis;
      }

      .notifications-data-label {
        display: none;

        text-transform: uppercase;
        color: #a1a1a1;
        font-size: 12px;
        font-weight: bold;
      }

      .notifications-data-content {
        color: black;
        font-size: 14px;
        font-weight: normal;
      }
    }

    @media screen and (max-width: 750px) {
      flex-direction: column;
      align-items: flex-start;

      padding: 10px;

      &.header {
        display: none!important;
      }

      .notifications-data-cell {
        margin-bottom: 12px;

        width: 100%!important;

        .notifications-data-label {
          display: block;
          margin-bottom: 3px;
        }
      }
    }
  }
}
