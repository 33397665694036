.assessments-table {
  .assessment-wrapper {
    flex-direction: row;
    justify-content: space-between;

    max-width: 900px;

    @media screen and (max-width: 720px) {
      flex-direction: column;
    }

    .assessment-column {
      margin-right: 70px;

      @media screen and (max-width: 720px) {
        margin-right: 0;
      }

      .assessment-cell {
        margin-bottom: 20px;

        .assessment-results {
          .assessment-results-header {
            font-size: 14px;
            font-weight: bold;
            margin-bottom: 5px;
          }
          .assessment-results-data {
            border-top: 1px solid #efeff2;
            padding-top: 5px;
            font-size: 14px;

            .data-row {
              padding-bottom: 5px;
              margin-bottom: 5px;
              border-bottom: 1px solid #efeff2;

              .data-key {
                text-transform: capitalize;
              }

              .data-value {
                font-weight: bold;
                margin-left: 20px;
              }
            }
          }
        }
      }
    }
  }
}
