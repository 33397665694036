.create-resource-container {
  padding: 1.5em;

  .form-content {
    flex-wrap: wrap;
  }
  h3 {
    margin: 0;
    padding-bottom: 1em;
    width: 100%;
    border-bottom: 1px solid gray;
  }

  .full-width {
    width: 100%;
  }

  .half-width {
    width: 48%;
  }
  .quarter-width {
    width: 24%;
  }

  .static-input-label {
    font-size: 14px;
    opacity: 0.7;
  }

  .checkbox-label {
    margin-bottom: 0;
  }

  .bottom-buttons {
    width: 100%;
    margin-top: 1em;

    button {
      min-width: 120px;
      &:first-child {
        margin-right: 20px;
      }
    }
  }

  .checkbox-label {
    margin-right: 5px;
  }

  .static-input-label.with-description {
    margin-bottom: 0.5em;
  }

  .field-description {
    font-size: 12px;
    margin: 0 0 1em;
  }

}
